<template>
  <div>
    <main class="content-1400 page-content">
      <div class="pad-4">
        <div class="pad-8">
          <h5 class="color-primary f-normal">
            Übersicht
          </h5>
          <h1>Meine Bestellungen</h1>
        </div>
        <div class="field mar-bottom-16">
          <input type="search" placeholder="Suche.." v-model="searchString">
        </div>
        <div class="bgcolor-white pad-24 br-10">
          <div v-if="loading !== true">
            <div class="grid-12 pad-4">
              <div class="col-sp-12 col-tb-1 row-sp-12">
                <h6 class="f-normal" style="font-size: 0.9em;">
                  ID
                </h6>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <h6 class="f-normal" style="font-size: 0.9em;">
                  Status
                </h6>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12">
                <h6 class="f-normal" style="font-size: 0.9em;">
                  E-Mail-Adressen
                </h6>
              </div>
              <div class="col-sp-12 col-tb-5 row-sp-12">
                <h6 class="f-normal" style="font-size: 0.9em;">
                  Zeitstempel
                </h6>
              </div>
            </div>
            <div v-if="!ordersFiltered.length" class="t-center pad-16">
              <p>Keine Bestellungen gefunden</p>
            </div>
            <div v-for="order in ordersFiltered" :key="order.id" class="grid-12 pad-4 pad-top-8 mar-top-4 pad-bottom-8 br-10" style="background-color: #00000007;">
            <div class="col-sp-12 col-tb-6 col-sd-1 row-sp-12 pad-top-16">
              <p class="pad-left-4">
                #{{ order.id }}
              </p>
            </div>
            <div class="col-sp-12 col-tb-6 col-sd-2 row-sp-12 pad-top-16">
              <span v-if="order.status === 0" class="label tiny yellow">
                In Freigabe
              </span>
              <span v-else-if="order.status === 1" class="label tiny green">
                Genehmigt
              </span>
              <span v-else-if="order.status === 2" class="label tiny red">
                Abgelehnt
              </span>
            </div>
            <div class="col-sp-12 col-sd-4 row-sp-12">
              <p style="font-size: 0.8em;">
                Besteller: <a class="color-primary" style="font-size: 1em;">{{ order.contactemail }}</a><br>
                Nutzer: <a class="color-primary" style="font-size: 1em;">{{ order.email }}</a><br>
                Genehmiger:
                <a style="font-size: 1em;" v-if="order.genehmigerEmail === '' && order.status == 1">Keine Genehmigung erforderlich</a>
                <a style="font-size: 1em;" v-else-if="order.genehmigerEmail === '' && order.status == 0">Liegt zur Genehmigung bei {{ this.SL(order.id) }}</a>
                <a style="font-size: 1em;" v-else class="color-primary">Wurde bearbeitet durch {{ order.firstname }} {{ order.lastname }} ({{ order.genehmigerEmail }})</a>
              </p>
            </div>
            <div class="col-sp-12 col-tb-12 col-sd-2 row-sp-12 pad-top-16">
                  <span class="label tiny">
                    {{ formatDate(order.timestamp) }}
                  </span>
            </div>
            <div class="col-sp-12 col-tb-12 col-sd-3 row-sp-12 t-right pad-top-16 pad-right-8">
              <button class="button primary tiny mar-right-8" @click="loadDetails(order.id)">
                Details
              </button>
            </div>
          </div>
          </div>
          <div v-else>
            <div class="t-center">
              <img src="assets/images/loading.gif" class="width-40">
              <p class="color-grey mar-top-4" style="font-size: 0.8em">
                Lädt
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <transition name="modal" id="OrderDetailsModal" style="position: fixed;">
      <div class="vueModal" v-if="OrderDetailsModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div>
              <h5 class="t-center">
                Bestelldetails
              </h5>
              <div class="mar-top-16">
                <p class="mar-bottom-16">
                  <b v-if="orderDetails.austausch == 1">Austauschgerät</b><b v-else>Erstgerät</b><br>
                  <b>Grund:</b> {{ orderDetails.reason }}<br>
                  <b>Nachricht Antragsteller:</b> {{ orderDetails.message }}<br>
                  <b>Neues Gerät:</b> {{ deviceName }}<br>
                  <b v-if="orderDetails.zubehoer">Zubehör:</b> {{ orderDetails.zubehoer }}<br>

                  <b>Unternehmen:</b> {{ orderDetails.company }}<br>
                  <b>Betroffener Nutzer (Name):</b> {{ orderDetails.name }}<br>
                  <b>Betroffener Nutzer (E-Mail):</b> {{ orderDetails.email }}<br>
                  <b>Kostenstelle (ohne führende Null):</b> {{ orderDetails.kostenstelle }}<br>
                  <b>Rufnummer:</b> {{ orderDetails.geraetphonenumber }}<br>
                  <b>Kaufdatum Altgerät:</b> {{ orderDetails.contractfrom }}<br><br>

                  <b>Firma:</b> {{ orderDetails.company }}<br>
                  <b>Straße:</b> {{ orderDetails.street }}<br>
                  <b>Hausnr.:</b> {{ orderDetails.hnr }}<br>
                  <b>PLZ:</b> {{ orderDetails.plz }}<br>
                  <b>Stadt:</b> {{ orderDetails.city }}<br>
                  <b>Land:</b> {{ orderDetails.country }}<br>
                  <b>Handelt es sich um eine Privatadresse?:</b> <span v-if="orderDetails.privatadress == 0">Nein</span><span v-else>Ja</span><br>
                  __________________________________________<br>
                  <b>Besteller-Name:</b> {{ orderDetails.contactname }}<br>
                  <b>Besteller-E-Mail: </b>{{ orderDetails.contactemail }}<br>
                  __________________________________________<br>
                  <b>Genehmiger:</b><br>
                  <span v-if="orderDetails.status === 0">
                    Liegt zur Genehmigung bei {{ this.SL(orderDetails.id) }}
                  </span>
                  <span v-else-if="orderDetails.status === 1">
                    Wurde genehmigt durch {{ orderDetails.firstname }} {{ orderDetails.lastname }} ({{ orderDetails.genehmigerEmail }})
                  </span>
                  <br>__________________________________________<br>
                  <b>Zeitstempel der Bestellung:</b><br>
                  {{formatDate(orderDetails.timestamp)}}
                </p>
              </div>
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="OrderDetailsModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "MyOrders",
  title: "Bestellung",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      myorders: [],
      loading: true,
      standOrtleiter: [],
      orderDetails: '',
      OrderDetailsModal: false,
      deviceName: '',
      searchString: ''
    }
  },
  computed:  {
    ordersFiltered(){
      return this.myorders.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    },
  },
  mounted() {
    this.getMyOrders()
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('de-DE', options).replace(',', '') + ' Uhr';
      return formattedDate;
    },
    getMyOrders(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getMyOrders', fD)
          .then((response) => {
            console.log(response.data)
            if(response.status === 250){
              _this.myorders = response.data
              _this.loading = false
              this.myorders.map(singleOrder=>{
                this.getStandortsLeiter(singleOrder.austausch, singleOrder.kostenstelle, singleOrder.id)
              })
            }
          })
          .catch(e => {console.error(e)})
    },
    loadDetails(id){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getOrderById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.orderDetails = response.data
              _this.OrderDetailsModal = true
              _this.getDeviceName(response.data.tarif)
            }
          })
          .catch(e => {console.error(e)})
    },
    getDeviceName(tarif){
      const _this = this
      const fD = new FormData()
      fD.append('tarif', tarif)
      fD.append('data', 'tarifdevice')
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getTarifData', fD)
          .then((response) => {
            if(response.status === 250){
              _this.deviceName = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getStandortsLeiter(austausch, kostenstelle, id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('austausch', austausch)
      fD.append('kostenstelle', kostenstelle)
      this.axios.post('bestellung/getStandortsLeiter', fD)
          .then((response) => {
            this.standOrtleiter.push({id:id, name:response.data.firstname+' '+response.data.lastname+' ('+response.data.email+')'})
            return response.data.firstname+' '+response.data.lastname+' ('+response.data.email+')'
          })
          .catch(e => {console.error(e)})
    },
    SL(orderId){
      const found = this.standOrtleiter.find(({id}) => id === orderId)
      return found.name
    }
  }
}
</script>

<style scoped>

</style>