<template>
  <div>
    <main>
      <section class="login" v-if="page === 'loginView.vue'">
        <div class="content-500">
          <div class="t-center pad-16">
            <img :src="portalLogo" class="width-200">
          </div>
          <section class="bgcolor-white br-10 pad-24">
            <div class="t-center">
              <h2>
                Anmelden
              </h2>
              <h6 class="color-grey f-normal">
                Bitte loggen Sie sich ein
              </h6>
              <p class="feedbackmessage t-center pad-8 color-orange" v-if="loginStatus">
                {{ loginStatus }}
              </p>
            </div>
            <div class="t-center pad-16">
              <a href="https://login.microsoftonline.com/c624e5a7-7ad8-44cf-baa8-c5ff4384869e/oauth2/v2.0/authorize?client_id=c44cbada-02a7-4106-bb84-8d3d13d761be&response_type=code&redirect_uri=https%3A%2F%2Feurogate-mobilfunk.eu%2Flogin%3Faccess%3Dtrue&response_mode=query&scope=offline_access%20user.read&state=12345">
                <button type="submit" class="button fluid primary">Anmeldung über Azure</button>
              </a>
            </div>
          </section>
          <p class="t-center pad-16">
            <a class="color-primary mar-right-8" href="https://www.mendo.de/impressum">Impressum</a>
            <a class="color-primary mar-left-8" href="https://www.mendo.de/datenschutz">Datenschutz</a>
          </p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

import router from "@/router";

export default {
  name: "loginView",
  title: "Login",
  components:{

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      page: this.$route.query.page ?? 'loginView.vue',
      token: this.$route.query.resetkey,
      cookie: false,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      loginStatus: '',
      forgotStatus: '',
      resetStatus: '',
      resetLoading: false,
      portalLogo: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.getPortalLogo()
    this.checkAutoLogin()
    this.azureLogin()
  },
  methods: {
    checkAutoLogin(){
      if(this.$route.query.key){
        const _this = this
        const fD = new FormData()
        fD.append('firstname', this.$route.query.firstname)
        fD.append('lastname', this.$route.query.lastname)
        fD.append('email', this.$route.query.email)
        fD.append('key', this.$route.query.key)
        this.axios.post('login/externAdminLogin', fD)
            .then((response) => {
              if (response.status === 250){
                this.auth.saveData(response.data)
                _this.$router.push(this.$route.query.redirect && (typeof this.$route.query.redirect  === 'string' || this.$route.query.redirect  instanceof String) ? decodeURI(this.$route.query.redirect) : 'Dashboard')

              }
            })
            .catch(e => {console.error(e)})
      }
    },
    getPortalLogo(){
      const _this = this
      const fD = new FormData()
      fD.append('data', 'companylogo')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.portalLogo = response.data
          })
          .catch(e => {console.error(e)})
    },
    azureLogin(){
      if(this.$route.query.access){
        const _this = this
        const fD = new FormData()
        fD.append('code', this.$route.query.code)
        fD.append('access', this.$route.query.access)
        this.axios.post('login/azureLogin', fD)
            .then((response) => {
              console.log(response)
              if (response.status === 250){
                this.auth.saveData(response.data)
                _this.$router.push(this.$route.query.redirect && (typeof this.$route.query.redirect  === 'string' || this.$route.query.redirect  instanceof String) ? decodeURI(this.$route.query.redirect) : 'Dashboard')
              }
            })
            .catch(e => {console.error(e)})
      }
    },
  }
}
</script>

<style scoped>
.login{
  padding-top: 20vh;
}
.login .field.simple input{
  max-width: 300px;
}

.forgotpassword a{
  font-size: 0.7em;
  text-decoration: underline;
  cursor: pointer;
}
.rememberme span{
  font-size: 0.8em;
}
.divider{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
  text-align: center;
  padding: 8px 0;
}
.divider:before,
.divider:after{
  content: ' ---------- ';
  color: rgba(0,0,0,0.4);
}
#LoginButton{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
}

.feedbackmessage{
  font-size: 0.8em;
}
.width-300{
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.field.simple label{
  font-size: 0.7em;
  font-weight: normal;
  display: inline;
  vertical-align: 2px;
  padding-left: 4px;
}
</style>