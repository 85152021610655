<template>
  <div>
    <form v-if="feedback == false" method="post" @submit.prevent="sendMessage()">
      <h4 class="pad-4 t-center">
        Technischer Support
      </h4>
      <p class="pad-4 mar-bottom-16 color-grey" style="white-space: pre-wrap;">
        {{ tsupporttext }}
      </p>
      <div class="grid-12">
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <div class="field">
            <label>Gerätbezeichnung:</label>
            <input type="text" name="geraetetyp" placeholder="Gerätetyp" v-model="devicename" required>
          </div>
        </div>
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <div class="field">
            <label>Kategorie der Störung:</label>
            <select name="category" v-model="category" required>
              <option value="" disabled selected style="color: rgba(191,191,191,.87);">
                Kategorie der Störung
              </option>
              <option value="Akku">
                Akku
              </option>
              <option value="Display">
                Display
              </option>
              <option value="Gehäuse">
                Gehäuse
              </option>
              <option value="Gerät defekt">
                Gerät defekt
              </option>
              <option value="Verbindung / Empfang">
                Verbindung / Empfang
              </option>
              <option value="Sonstiges">
                Sonstiges
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="pad-4">
        <div class="field">
          <label>Rufnummer des betroffenen Gerätes:</label>
          <input type="tel" name="phone" placeholder="Rufnummer des betroffenen Gerätes" required v-model="phone">
        </div>
      </div>
      <div class="pad-4">
        <div class="field">
          <label>Beschreibung:</label>
          <textarea rows="2" name="message" placeholder="Bitte beschreibe das Problem" required v-model="message" />
        </div>
      </div>
      <h6 class="t-center">
        Kontaktdaten
      </h6>
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-4">
          <div class="field">
            <label>Name:</label>
            <input type="text" name="name" placeholder="Name" required v-model="name">
          </div>
        </div>
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <div class="field">
            <label>Telefonnummer:</label>
            <input type="tel" name="phonenumber" placeholder="Telefonnummer" required v-model="phonenumber">
          </div>
        </div>
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <div class="field">
            <label>E-Mail:</label>
            <input type="email" name="email" placeholder="E-Mail" required v-model="email">
          </div>
        </div>
      </div>
      <div class="pad-8" />
      <div class="pad-4">
        <button class="button primary fluid" type="submit">
          Nachricht senden
        </button>
      </div>
    </form>
    <div v-else>
      <div class="t-center pad-16">
        <h3>
          Ihre Supportanfrage wurde versendet
        </h3>
        <h4 class="f-normal color-grey">
          <p>Wir werden uns schnellstmöglich um Ihr Anliegen kümmern.</p>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "technischerSupport",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      tsupporttext: '',
      devicename: '',
      category: '',
      phone: '',
      message: '',
      name: this.user.firstname+' '+this.user.lastname,
      phonenumber: '',
      email: this.user.email,
      feedback: false,

    }
  },
  mounted() {
    this.getText();
  },
  methods:{
    getText(){
      const _this = this
      const fD = new FormData()
      fD.append('data', 'tsupporttext')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.tsupporttext = response.data
          })
          .catch(e => {console.error(e)})
    },
    sendMessage(){
      const _this = this
      const fD = new FormData()
      fD.append('devicename', this.devicename)
      fD.append('category', this.category)
      fD.append('phone', this.phone)
      fD.append('message', this.message)
      fD.append('name', this.name)
      fD.append('email', this.email)
      fD.append('phonenumber', this.phonenumber)
      this.axios.post('dashboard/technischerSupport', fD)
          .then((response) => {
            if(response.status === 250){
              this.devicename = ''
              this.category = ''
              this.phone = ''
              this.message = ''
              this.name = ''
              this.phonenumber = ''
              this.email = ''
              _this.feedback = true
            }
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>

</style>